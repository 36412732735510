/* MAIN ENTRY POINT FOR TAILWIND */

/* GLOBAL STYLES */

/* Injects Tailwind's base styles, which include a pre-made set of normalized styles. */
@tailwind base;
/* Injects any component classes you've defined in your Tailwind configuration. This is 
where you can add custom styles using Tailwind's @apply directive. */
@tailwind components;
/* Injects all of Tailwind's utility classes, which are the core of Tailwind's design system. */
@tailwind utilities;

html {
  text-size-adjust: none;
  scroll-behavior: smooth;
}
/* Browser Normalization + Legability on Mac Devices */
body {
  margin: 0;
  font-family: -apple-system, 'Sora', sans-serif, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
a,
button {
  color: var(--primary-color);
}
/* Default font for code elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.card {
  background-color: var(--secondary-color);
  border-color: var(--border-color);
}

/* CUSTOM CSS */

.DynamicLogo {
  background: linear-gradient(to left, #3773e5, #4285f4);
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.qr-stained-glass-effect {
  position: relative;
  display: inline-block; /* Ensure the container is inline-block or block */
  overflow: hidden; /* Clip the pseudo-element to the border radius */
}

.qr-stained-glass-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white overlay */
  backdrop-filter: blur(3px); /* Frosted glass effect */
  z-index: 1;
}

/* INPUT SECTION */
/****************/
.css-label svg {
  @apply mr-2;
}
input.css-input,
textarea.css-input {
  background-color: #f0f0f0; /* Light grey background */
  border: 1px solid transparent; /* Transparent border that will change on focus */
  border-radius: 3px;
  box-shadow:
    inset 0px 1px 2px #d1d1d1,
    /* Darker grey for depth */ inset 0px 0px 80px #ffffff; /* White for light effect */
  padding: 5px 15px;
  margin-bottom: 15px; /* Space between inputs */
  transition: border-color 0.25s ease; /* Smooth transition for border color */
  width: 100%;
  outline: none; /* Remove default focus outline */
}
input.css-input:focus,
textarea.css-input:focus {
  border-color: #4285f4;
}
textarea.css-input {
  /* Initial styles for the textarea */
  height: 90px; /* Initial height (adjust as needed) */
  transition: height 0.3s ease; /* Transition effect for height change */
  /* Add other styles as needed (padding, font, border, etc.) */
}

.input-container {
  transition:
    max-height 0.5s ease-out,
    opacity 0.3s ease-in 0.5s; /* Opacity transition starts after max-height transition */
  overflow: hidden;
  height: 0; /* Initially, the container is collapsed */
  opacity: 0; /* Initially, the content is invisible */
}

.input-container.visible {
  max-height: 100px; /* Adjust as needed for your input field size */
  opacity: 1; /* Content becomes visible after the container expands */
}

.css-tooltip {
  background-color: rgb(49, 49, 49, 0.9);
  width: 90%;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 8px 14px; /* Add horizontal padding */
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  white-space: nowrap;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Optional: Add shadow for depth */
}

/* Arrow at the top of the tooltip */
.css-tooltip::before {
  content: '';
  position: absolute;
  bottom: 100%; /* Position at the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(49, 49, 49, 0.9);
}

.css-button-tooltip {
  color: #ff0000; /* Red color for text */
  background-color: #fee2e2; /* Light red/pink background */
  padding: 8px 16px; /* Padding around the text */
  border-radius: 4px; /* Rounded corners */
  font-weight: bold; /* Bold font */
  display: inline-block; /* Only take up as much width as the content */
  position: absolute; /* Absolute positioning within the container */
  left: 50%; /* Position to the halfway of the container */
  transform: translateX(-50%); /* Shift back to center perfectly */
  bottom: 100%; /* Position above the button */
  margin-bottom: 12px; /* Adjust this value as needed for spacing between the error message and the button */
  width: max-content;
}

/* THEME */
/******************/

/* #root div,
#root div header nav {
  transition:
    color 1s ease-in-out,
    border 1s ease-in-out,
    background-color 1s ease-in-out;
} */

/* MODAL  CONTENT */
/******************/

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
}

/* CONTAINER RAISED */
/********************/

.css-container-raised {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.error-message {
  color: red;
  text-align: center;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* REACT LAZY SPINNER */
/**********************/
@keyframes grow-shrink {
  0%,
  100% {
    transform: scale(1); /* Start and end at normal size */
  }
  50% {
    transform: scale(3); /* Grow to 1.5 times its size */
  }
}
.animate-pulse {
  animation: grow-shrink 1s ease-in-out infinite;
}
.dashed-border {
  border: 2px dashed rgb(209, 209, 209); /* Adjust the color and size as needed */
}

/* Add a custom class to your Textarea component */
.textarea-outlined {
  position: relative;
  border: 1px solid #ccc; /* Or the color you wish to use */
}

/* Style the label to ensure it does not break the border */
.textarea-outlined-label {
  position: absolute;
  background-color: white;
  padding: 0 5px;
  top: -0.5em; /* Adjust this value to move label up */
  left: 1.5em; /* Adjust to align with the Textarea text */
  z-index: 10;
}

/* Make sure the label background matches the Textarea background */
.textarea-outlined-label::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
}

/**
* -------------
 * FORM STYLING
 * ------------
 */

/* FORM - TEXTAREA AND INPUT */
.form-field {
  @apply bg-mac-6 relative bottom-5 py-2 text-center rounded-md border;
  @apply focus:bg-white;
}
.form-field:focus {
  box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.1);
}

/* FORM - LABEL */
.form-label {
  @apply ml-2 relative bottom-5 flex items-center mb-1 font-medium text-blue-600;
}



/**
* -----------
 * HELLO VIEW
 * ----------
 */

 /* Add this CSS to your component or main stylesheet */
.hello-view {
  padding: 16px;
}

@media (min-width: 640px) {
  .hello-view {
    padding: 32px;
  }
}
